@import "./src/index.scss";

.profile-input-container {
  margin-top: 25px;

  &.full {
    grid-column: span 2;
  }

  &.half {
    grid-column: span 1;
  }

  label.main-input-label {
    @include form-font-semibold;
    line-height: 24px;
    display: block;
    margin-bottom: 11px;
  }

  label {
    margin-top: 10px;

    @include form-font-regular;
  }

  input,
  select {
    overflow: hidden;
    display: block;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #a9a9a9;
    box-sizing: border-box;
    padding: 9px 15px;
    margin-top: 10px;
    &:disabled {
      background-color: $color-white;
      color: $color-black;
    }
  }

  .document-upload {
    div {
      display: inline-block;
      width: 80%;
    }

    input[type="file"] {
      display: none;
    }

    button {
      @include form-font-medium;
      background-color: $submit-button-background-color;
      border-radius: 3px;
      border: none;
      text-align: center;
      color: $color-white;
      padding: 13px 24px;
      margin-top: 10px;
      max-width: 99px;
      outline: none;
    }
  }

  .multi-checkboxes {
    .multi-checkbox {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      input[type="text"] {
        border: 1px solid #a9a9a9;

        &:focus {
          outline: none;
          border: 1px solid $color-black;
        }
      }

      .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        position: relative;

        &.selected {
          .custom-checkbox {
            background-color: $color-white;
            border-radius: 3px;
            -webkit-transform: rotate(0deg) scale(1);
            -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
            opacity: 1;
            border: 1.5px solid $submit-button-background-color;

            &:after {
              -webkit-transform: rotate(45deg) scale(1);
              -ms-transform: rotate(45deg) scale(1);
              transform: rotate(45deg) scale(1);
              opacity: 1;
              left: 5px;
              top: 1px;
              width: 4px;
              height: 10px;
              border: solid $submit-button-background-color;
              border-width: 0 2px 2px 0;
              background-color: transparent;
              border-radius: 0;
            }
          }
        }

        input[type="checkbox"] {
          width: auto;
          margin-top: 0;
          position: absolute;
          opacity: 0;
        }

        .custom-checkbox {
          position: absolute;
          top: 0;
          left: 0;
          height: 18px;
          width: 18px;
          background-color: transparent;
          box-sizing: border-box;
          transition: all 0.1s ease-out;
          -webkit-transition: all 0.1s ease-out;
          -moz-transition: all 0.1s ease-out;
          -o-transition: all 0.1s ease-out;
          border: 1px solid #a22028;
          border-radius: 3px;

          &:after {
            position: absolute;
            content: "";
            left: 12px;
            top: 12px;
            height: 0;
            width: 0;
            border-radius: 3px;
            border: 1px solid $submit-button-background-color;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 1;
            transition: all 0.1s ease-out;
            -webkit-transition: all 0.1s ease-out;
            -moz-transition: all 0.1s ease-out;
            -o-transition: all 0.1s ease-out;
          }
        }

        .checkbox-label {
          @include form-font-medium;
          margin-left: 30px;
        }
      }
    }
  }

  .multi-radio-buttons {
    .radio-button-divider {
      margin: 10px 0;

      label.radio {
        @include form-font-medium;
      }

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        margin: 35px 0;
      }
    }

    .radio {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      font-size: 14px;
      margin: 5px;
      .label {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 18px;
        height: 18px;
        border: 1px solid #a22028;
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
        &:after {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #a22028;
          transform: scale(0);
          transition: all 0.2s ease;
          opacity: 0.08;
          pointer-events: none;
        }
        &:hover {
          .label:after {
            transform: scale(3.6);
          }
        }
      }
    }

    input[type="radio"]:checked + .label {
      border-color: #a22028;
      &:after {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
      }
    }
  }

  .yes-no-radio-button {
    border-radius: 3px;
    width: 232px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    outline: none;

    //Responsive Mobile
    @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
      width: 100%;
    }

    .answer-selection {
      width: 116px;
      height: 43px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 15px;
      cursor: pointer;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        width: 50%;
      }

      label {
        @include form-font-medium;
        font-size: 14px;
        line-height: 23px;
      }
    }

    .answer-selection.selected {
      background: $color-white;
    }

    .radio {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      font-size: 14px;
      margin: 5px;
      .label {
        position: relative;
        display: block;
        float: left;
        margin-right: 10px;
        width: 18px;
        height: 18px;
        border: 1px solid #a22028;
        border-radius: 100%;
        -webkit-tap-highlight-color: transparent;
        &:after {
          content: "";
          position: absolute;
          top: 3px;
          left: 3px;
          width: 12px;
          height: 12px;
          line-height: 12px;
          border-radius: 100%;
          background: #a22028;
          transform: scale(0);
          transition: all 0.2s ease;
          opacity: 0.08;
          pointer-events: none;
        }
        &:hover {
          .label:after {
            transform: scale(3.6);
          }
        }
      }
    }

    input[type="radio"]:checked + .label {
      border-color: #a22028;
      &:after {
        transform: scale(1);
        transition: all 0.2s cubic-bezier(0.35, 0.9, 0.4, 0.9);
        opacity: 1;
      }
    }
  }

  .radio-hidden {
    display: none;
  }

  .comp-error-msg {
    width: 100%;
    margin-top: 5px;
    color: #a22028;
  }
}
