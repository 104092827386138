@import "../../../../index.scss";

.feature-wrapper {
  position: relative;
  height: 518px;

  .feature-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    bottom: 150px;

    gap: 78px;

    z-index: 3;

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .box-wrap {
      width: 50%;

      .feature-component-image {
        width: 100%;
        height: auto;
      }

      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .feature-content-wrapper {
      .feature-heading {
        margin-bottom: 50px;
      }

      .MuiDivider-root {
        margin: 40px 0;
      }
      @media only screen and (max-width: 1280px) {
        .feature-heading {
          margin-bottom: 30px;
        }
        .MuiDivider-root {
          margin: 20px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    height: 700px;
  }
  @media only screen and (max-width: 900px) {
    height: 700px;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    padding: $homepage-padding-sm 0;
    // bottom: 0;

    .feature-section-wrapper {
      position: relative;
      bottom: 0;
      gap: 20px;
    }
  }
}
