$color-black: #000;
$color-white: #fff;
$color-dark-red: #a41e24;
$submit-button-background-color: #a41e24;
$submit-button-box-shadow-color: rgba(164, 30, 36, 0.3);
$already-selected-tab-color: #b75359;
$loading-bar-background-color: #d80000;
$placeholder-border-color: #f3f3f3;
$validation-error-color: #ff0000;
$profile-background-color: #f8f8f8;
$gray-button-color: #ebebeb;
$material-gray: rgba(0, 0, 0, 0.7);
$profile-body-width: 1116px;

$primary-font-semibold: Montserrat-SemiBold, sans-serif;
$primary-font: Montserrat-Regular, sans-serif;
$primary-font-bold: Montserrat-Bold, sans-serif;
$primary-font-thin: Montserrat-Thin, sans-serif;
$primary-font-medium: Montserrat-Medium, sans-serif;

$manrope-regular-font: Manrope-Regular, sans-serif;

$break-mobile: 300px;
$break-big-mobile: 550px;
$break-tablet: 768px;
$break-large: 1024px;
$break-xl: 1600px;

// Homepage variables
$primary-color: #b62026;
$homepage-background-color: #f8f8f8;

html {
  background-color: $profile-background-color;
  font-family: $primary-font;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span,
input {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $primary-font;
}
#root {
  background-color: $color-white;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("/fonts/montserrat-regular.ttf") format("woff"),
    url("/fonts/montserrat-regular.ttf") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/fonts/montserrat-bold.ttf") format("woff"),
    url("/fonts/montserrat-bold.ttf") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("/fonts/montserrat-semibold.ttf") format("woff"),
    url("/fonts/montserrat-semibold.ttf") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Thin";
  src: url("/fonts/montserrat-thin.ttf") format("woff"),
    url("/fonts/montserrat-thin.ttf") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("/fonts/montserrat-medium.ttf") format("woff"),
    url("/fonts/montserrat-medium.ttf") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Montserrat-Black";
  src: url("/fon") format("woff"),
    url("/fonts/montserrat-medium.ttf") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Manrope-Regular";
  src: url("/fonts/Manrope-Regular.ttf") format("woff"),
    url("/fonts/Manrope-Regular.ttf") format("woff2");
  font-weight: normal;
}

@mixin form-font-large {
  font-family: $primary-font-semibold;
  font-size: 36px;
  color: $color-black;
  font-weight: 400;
  line-height: 43px;
  text-align: left;
}

@mixin form-font-regular {
  font-family: $primary-font;
  font-size: 14px;
  color: $color-black;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

@mixin form-font-medium {
  font-family: $primary-font-medium;
  font-size: 14px;
  color: $color-black;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

@mixin form-font-semibold {
  font-family: $primary-font-semibold;
  font-size: 14px;
  color: $color-black;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
}

@mixin form-font-forget-password {
  font-family: $primary-font-semibold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: $color-black;
  font-weight: bold;
}

@mixin navigation-bar-logo {
  background: url("./assets/images/header_logo.png") no-repeat;
  height: 65px;
  width: 211px;
  background-size: contain;
}

@mixin image-text-large {
  font-family: $primary-font-semibold;
  font-size: 36px;
  color: $color-white;
  font-weight: 400;
  line-height: 43px;
  text-align: left;
}

@mixin image-text-copyright {
  color: $color-white;
  font-family: $primary-font-semibold;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

@mixin button-standard {
  @include form-font-medium;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 13px 26px;
  text-align: center;
}

@mixin button-red {
  @include button-standard;
  background-color: $submit-button-background-color;
  color: $color-white;
}

@mixin signup-button-text {
  color: $color-white;
  font-family: "Montserrat-Bold", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}

@mixin validation-error-text {
  @include form-font-medium;
  color: $primary-color;
}

.loading-bar {
  height: 3px;
  background-color: $loading-bar-background-color;
  position: absolute;
  top: 0;
  z-index: 1031;
}

.alert {
  font-size: 13px;
  font-family: $primary-font;
  font-weight: 600;
  margin-top: 5px;
  width: auto;

  &.error {
    color: $color-dark-red;
  }
}

.button-with-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

//remove light blue background color when inputs are autofilled
input:-webkit-autofill {
  box-shadow: 0 0 0 100px #fff inset;
}

// Homepage mixins
$homepage-padding: 150px;
$homepage-padding-lg: 100px;
$homepage-padding-sm: 30px;

@mixin homepage-padding {
  padding: 0 $homepage-padding;

  @media (max-width: 1280px) {
    padding: 0px $homepage-padding-lg;
  }
  @media (max-width: 900px) {
    padding: 0px $homepage-padding-sm;
  }
}

@mixin homepage-line-centered-top {
  position: absolute;
  width: 100px;
  height: 0;

  top: -20px;
  left: calc(50% - 50px);

  border: 1.5px solid #b62026;
  transform: rotate(90deg);
}
