@import "../../../index";

.company-landing {
  .company-landing-cover {
    height: 100%;
    width: 100%;
    background: url("../../../assets/images/img-landing-page.png") no-repeat;
    background-size: cover;
    position: relative;

    @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
      background: none;
    }
  }

  .company-box-layout {
    max-width: 1116px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .company-box {
    background-color: #fff;
    max-width: 660px;
    width: 100%;
    padding: 40px;
    border-radius: 3px;
    font-weight: bold;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: -10%;
    margin-top: 270px;

    @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
      background: none;
      width: 80%;
      margin: 0 auto;
      display: block;
      margin-top: 5%;
      padding: 15px;
    }

    hr {
      border-top: 1px solid #fafafa;
      margin-bottom: 30px;
    }

    .company-box-title {
      @include form-font-semibold;
      font-size: 36px;
      margin-bottom: 30px;

      //tablet responsive
      @media screen and (max-width: $break-tablet) {
        line-height: 1;
      }
    }

    .company-box-desc {
      @include form-font-regular;
      font-size: 16px;
      width: 100%;
      line-height: 32px;
      font-weight: 500;
      color: #000;
    }

    .control-button-container {
      display: flex;
      align-items: center;
      justify-content: left;
      width: 100%;
      margin-top: 30px;

      button {
        @include button-red;

        //Mobile and tablet responsive
        @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
          width: 100%;
        }
      }
    }
  }
}
