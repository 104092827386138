@import "./src/index.scss";
@import "../adminTables/tableStyles.scss";

.admin-matching-container {
  margin: 30px auto;
  padding: 30px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000020;

  h2 {
    @include form-font-semibold;
    font-size: 20px;
    line-height: 22px;
  }

  .title-btn-wrapper {
    display: flex;
    justify-content: space-between;

    .export-button {
      @include form-font-medium;
      min-width: 100px;
      padding: 10px 15px;
      color: #fff;
      background: #a41e24;
      border: none;
      border-radius: 3px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      outline: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  h3 {
    @include form-font-semibold;
    font-size: 18px;
    padding-top: 30px;

    &.custom {
      padding-top: 0;
    }
  }

  .admin-table-container {
    width: auto;
    background: transparent;
    border-radius: 0;
    box-shadow: none;
    padding: 0;

    &.custom {
      padding-top: 0;
    }

    .admin-table {
      .admin-row-experts {
        grid-template-columns: 3.5fr 4fr 4fr 3fr 4fr;

        td {
          @include form-font-medium;
        }

        tr.align-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        td.clickable-cell {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          cursor: unset;

          & > * {
            border-bottom: none;
            padding-left: 0;
          }

          .expert-name {
            padding: 0;
            margin-top: 15px;
            img {
              padding: 0;
              padding-left: 10px;
            }
          }

          .expert-email {
            padding: 0;
            margin-bottom: 15px;
          }
        }

        td.clickable-cell .expert-name:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .admin-matching-fields {
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    grid-column-gap: 20px;
    margin-top: 30px;
    margin-bottom: 15px;

    .matching-field {
      .matching-field-lbl {
        @include form-font-semibold;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
      }
    }
  }

  .matching-field-custom {
    max-width: 32%;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .admin-matching-company {
    margin: 10px 10px;
  }

  .company-details-desc {
    margin: 30px 0;
    @include form-font-medium;
    font-size: 16px;
  }

  .radio-inputs-wrapper {
    display: flex;
    margin-top: 10px;
    @include form-font-medium;
    align-items: center;

    input[type="radio"] {
      /* Remove most all native input styles */
      appearance: none;
      // Microsoft Edge and IE Mobile support this property with the -webkit- prefix,
      // rather than -ms- for interop reasons (https://caniuse.com/?search=appearance)
      -webkit-appearance: none;
      // -moz-appearance:none did not remove the dropdown arrow in the select tag
      //(https://caniuse.com/?search=appearance)
      -moz-appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      /* Not removed via appearance */
      margin: 0;

      width: 18px;
      height: 18px;
      border: 1px solid #a22028;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      cursor: pointer;
    }

    input[type="radio"]::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: all 0.2s ease;
      background-color: #a22028;
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }

    label {
      cursor: pointer;
    }

    label:first-of-type {
      margin-right: 30px;
    }
  }
}

.go-back-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    margin-left: 5px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.matching-field-custom {
  max-width: 33%;
}

.admin-column-width {
  grid-template-columns: 2fr 3.5fr 3.5fr 2.5fr 2.5fr 1.5fr 1.5fr;
  td {
    @include form-font-medium;
  }
}

.open-details {
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
}
