@import "./src/index.scss";

.image-landing-page {
  width: 100%;
  position: relative;
}

.landing-page-card {
  position: absolute;
  background-color: $color-white;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 50px 15px 50px;
  margin: -170px 0px 30px 600px;
  width: 35%;
}

.landig-page-title {
  @include form-font-large;
}

.lending-create-profile-button {
  @include form-font-medium;
  background-color: $submit-button-background-color;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: $color-white;
  padding: 13px 26px;
  margin-top: 32px;
  margin-bottom: 15px;
  outline: none;
}

.landing-page-line {
  margin-top: 25px;
  border: 1px solid $placeholder-border-color;
  margin-bottom: 20px;
}
.landing-page-paragraph {
  @include form-font-medium;
}
