@import "./src/index.scss";

.terms-container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: absolute;

  .terms-content-container {
    padding: 31px;
    max-width: 1118px;
    width: 100%;
    height: 600px;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 114px;
    background-color: #fff;
    overflow: scroll;
    overflow-x: hidden;
    text-align: justify;

    //Responsive Mobile & Tablet
    @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
      max-width: 80%;
      margin-bottom: 50px;
      margin-top: 20px;
    }

    .terms-title {
      font-family: "Montserrat-SemiBold", sans-serif;
      font-size: 24px;
      line-height: 29px;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        font-size: 18px;
      }
    }

    .terms-content {
      font-family: "Montserrat-Regular", sans-serif;
      font-size: 16px;
      line-height: 32px;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        font-size: 14px;
      }
    }
    .indent-none {
      margin-bottom: 20px;
      margin-top: 20px;
      font-weight: bold;
    }
    .indent-first {
      margin-left: 25px;
      margin-bottom: 10px;
    }
    .indent-second {
      margin-left: 50px;
      margin-bottom: 10px;
    }
    .indent-third {
      margin-left: 75px;
      margin-bottom: 10px;
    }
    .underlined {
      text-decoration: underline;
    }
    .font-weight--bold {
      font-family: "Montserrat-SemiBold", sans-serif;
    }
  }
  .navbar-arrow {
    display: none;
  }
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  margin-right: 3px;
  padding-right: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a41e24;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(164, 30, 36, 0.3);
}
