@import "./src/index.scss";

.error-page-main-wrapper {
  padding: 50px;

  .back-to-home {
    text-decoration: underline;
    cursor: pointer;
  }

  .error-page-container {
    @include form-font-regular;
    font-size: 20px;

    //50px padding calculated
    margin-top: -50px;

    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .error-page-text {
      .error-page-description {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        margin-right: 10px;
        padding-right: 10px;

        // Responsive Mobile
        @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
          border-right: none;
          display: block;
        }
      }

      // Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        display: block;
        font-size: 14px;
      }
    }
  }
}
