@import "./src/index.scss";

.copyright-container {
  display: none;
  position: relative;
  top: -150px;

  //Responsive Mobile & Tablet
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    bottom: 0;
    text-align: center;

    display: initial;
  }
  .copyright-text-container {
    position: absolute;
    width: 100%;
    padding: 15px 0px;

    .copyright-text {
      @include form-font-medium;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      display: block;
      bottom: 0;

      a {
        color: $submit-button-background-color;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          border-bottom: 1px solid $submit-button-background-color;
        }
      }
    }
  }
}
