@import "./src/index.scss";

.doc-btn-light {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  background-color: #ebebeb;
  color: #000;
  border-radius: 3px;
  border: none;
  text-align: center;
  padding: 10px 18px;
  cursor: pointer;
}

.doc-btn-red {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  background-color: #a41e24;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  padding: 10px 18px;
  cursor: pointer;
}

.document-info-text {
  @include form-font-semibold;
  line-height: 40px;
  //Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    line-height: 20px;
  }
}

.document-info-subtext {
  font-family: Montserrat-Regular, sans-serif;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;

  @media screen and (max-width: $break-big-mobile) {
    margin-top: 20px;
  }
}

.document-upload-wrapper {
  .document-controls {
    .document-upload-input {
      display: none;
    }

    //Responsive Mobile
    @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
      .document-name {
        display: block;
      }
    }

    .document-upload-button {
      @extend .doc-btn-red;
      margin-right: 15px;
      outline: none;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .document-upload-label {
    line-height: 30px;
  }

  .document-buttons {
    padding-top: 25px;
    outline: none;

    .document-delete {
      @extend .doc-btn-light;
      margin-right: 15px;
      outline: none;
    }

    .document-download {
      @extend .doc-btn-red;
      outline: none;
      // For link
      text-decoration: none;

      // custom class only for the Preview button in the sidebar
      &.custom {
        margin-top: 30px;
        display: inline-block;
      }
    }

    //Responsive Mobile
    @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
      display: flex;

      .document-delete,
      .document-download {
        outline: none;
        width: 100%;
      }
    }
  }
}
