@import "../../../../index";

.delete-modal-content {
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 10px;
    color: #a41e24;
  }

  .delete-alert {
    font-size: 13px;
    background-color: #e1e1e1;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .control-button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      @include form-font-medium;
      background-color: $submit-button-background-color;
      border-radius: 3px;
      border: none;
      text-align: center;
      color: $color-white;
      padding: 13px 24px;
      margin-left: 20px;
      cursor: pointer;
      outline: none;

      &.cancel {
        background-color: $gray-button-color;
        color: $color-black;
      }

      //Resonsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
.responsive-modal-container {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 90px;
  overflow: "hidden";
  position: absolute;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  outline: 0;
  //Mobile Responsive
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    width: 85%;

    padding: 20px;
    border-radius: 5px;
  }
}
