@import "../../../../index.scss";

.testimonials-section {
  background-color: $homepage-background-color;

  .quote-wrapper {
    position: relative;
    top: -150px;
    min-height: 528px;
    background: $primary-color;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: $homepage-padding;

    .quote-icon {
      width: 76px;
      height: 61px;
      margin-bottom: 30px;
    }
    .quote-heading {
      color: #fff;
      font-size: 24px;
      font-family: $primary-font;
      line-height: 34px;
      text-align: center;
    }

    .quote-illustration {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: rotate(-180deg);
    }
  }

  .testimonials-wrapper {
    .testimonial-cards {
      display: flex;
      @include homepage-padding();

      .testimonial-box {
        width: 50%;
      }

      justify-content: center;

      gap: 80px;

      position: relative;
      top: -300px;

      .testimonial-card {
        min-height: 320px;
        max-width: 1000px;

        .testimonial-icon {
          margin-bottom: 15px;
        }

        .testimonial-paragraph {
          color: #000;
          margin-bottom: 50px;
        }

        .testimonial-img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-right: 20px;
        }

        .testimonial-name {
          font-family: $primary-font-bold;
          font-size: 18px;
          line-height: 22px;
          color: #000;
          word-break: break-word;
        }

        .testimonial-role {
          color: $primary-color;
        }
      }
    }
    .navigation-dots {
      display: flex;
      justify-content: center;
      position: relative;
      top: -230px;

      .active .navigation-dot {
        background-color: $primary-color;
      }

      .navigation-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        background: rgba(132, 136, 148, 0.2);
      }
    }
  }

  @media (max-width: 1280px) {
    .quote-wrapper {
      padding: $homepage-padding-lg;
    }
  }
  @media (max-width: 768px) {
    .quote-wrapper {
      padding: $homepage-padding-lg $homepage-padding-sm;
    }
  }

  @media (max-width: 1024px) {
    .testimonials-wrapper {
      .testimonial-cards {
        flex-wrap: wrap;
        .testimonial-box {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .testimonials-wrapper {
      .testimonial-cards {
        gap: 40px;
      }
    }
    .quote-wrapper {
      position: relative;
      top: -350px;
      min-height: 528px;

      .quote-icon {
        width: 76px;
        height: 61px;
        margin-bottom: 30px;
      }

      .quote-heading {
        font-size: 21px;
        line-height: 29px;
      }
    }
  }

  @media (max-width: 500px) {
    .quote-wrapper {
      top: -350px;
      min-height: 328px;

      .quote-icon {
        width: 56px;
        height: 41px;
        margin-bottom: 30px;
      }

      .quote-heading {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
}
