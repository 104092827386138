@import "./src/index.scss";
.partner-logos {
  display: flex;
  background-color: $homepage-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

.partner-logo-image {
  width: 350px;
}