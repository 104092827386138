@import "./src/index.scss";
.pagination {
  display: flex;
  justify-content: flex-end;

  .disabled {
    display: none;
  }

  .control {
    list-style: none;
    width: auto;

    font-weight: 700;
    background-color: #fff;
    border: 2px solid #a1a1a122;
    border-radius: 3px;
    margin: 0 1px;

    a {
      font-family: "Montserrat-Medium", sans-serif;
      display: inline-block;
      cursor: pointer;
      padding: 10px 15px;
    }

    &.active {
      color: #fff;
      background-color: #a41e24;
    }

    &.navigation {
      color: #a1a1a1cc;
    }

    &:hover {
      opacity: 0.85;
    }

    &:not(.active):hover {
      color: #000;
      background-color: #a1a1a133;
    }
  }
}
