@import "./src/index.scss";

.auth-form-wrapper {
  background-color: $homepage-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .auth-form-container {
    position: relative;
    box-sizing: border-box;
    padding: 50px;
    z-index: 1;
    border-radius: 10px;

    width: fit-content;

    margin: 0 auto;

    top: -280px;

    width: 100%;
    height: 100%;
    max-width: 680px;
    z-index: 1;

    overflow-x: hidden;

    &.forgot-password-form-container {
      @media screen and (max-width: 768px) {
        top: -220px;
      }
    }

    &.reset-password-form-container {
      top: 15%;
    }

    .auth-input-container {
      width: 100%;
      box-sizing: border-box;

      .auth-input {
        display: block;
        overflow: hidden;
        border: 1.5px solid #00000033;
        border-radius: 6px;
        padding: 15.5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;

        font-family: $primary-font-medium;
        font-size: 15px;
        line-height: 22px;

        &::placeholder {
          font-family: $primary-font-medium;
          font-size: 15px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.3);
        }

        &:focus {
          border: 1.5px solid $color-black;
          outline: none;
        }
      }

      .terms-text {
        font-family: $primary-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        color: #000000;

        a {
          color: $primary-color;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .auth-subinput-container {
      // margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .auth-checkbox-container {
        display: flex;
        align-items: center;

        input {
          width: 18px;
          height: 18px;
        }

        .auth-sublabel {
          @include form-font-medium;
          margin-left: 8px;
        }
      }

      .auth-link {
        font-family: $primary-font;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;

        text-decoration: none;
        color: $primary-color;

        // Responsive Mobile & Tablet
        @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
          text-align: center;
          width: 100%;
        }
      }
    }

    .auth-submit-button {
      @include button-red;
      font-size: 15px;
      line-height: 22px;
      font-family: $primary-font-semibold;

      width: 100%;

      padding: 20px 60px;
      margin: 30px 0 10px;

      background: $primary-color;
      border-radius: 6px;
    }

    .auth-signup-submit-button {
      @include button-red;
      font-size: 15px;
      line-height: 22px;
      font-family: $primary-font-semibold;

      width: 100%;
      transition: 0.3s;

      padding: 20px 60px;
      margin: 30px 0 10px;

      background: $primary-color;
      border-radius: 6px;
    }

    .auth-signup-submit-disabled {
      @include button-red;
      font-size: 15px;
      line-height: 22px;
      font-family: $primary-font-semibold;

      width: 100%;
      transition: 0.3s;

      padding: 20px 60px;
      margin: 30px 0 10px;

      background: $primary-color;
      border-radius: 6px;
      background-color: $gray-button-color;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .auth-text-container {
      font-family: $primary-font;
      font-size: 15px;
      line-height: 18px;

      &.email-success-link {
        display: block;
        text-align: center;
      }

      // Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        margin-bottom: 20px;
        text-align: center;
        margin-top: 20px;
      }

      // Responsive Tablet
      @media screen and (min-width: $break-tablet) and (max-width: $break-large) {
        text-align: left;
      }

      .auth-link {
        color: $primary-color;
        text-decoration: none;
        margin-left: 3px;
      }

      // Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        text-align: left;
      }
    }
  }
  .profile-type-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    margin-bottom: 40px;

    .company-label,
    .expert-label {
      width: 290px;
      padding: 20px 0px;
      position: relative;

      font-family: $primary-font-semibold;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      border-radius: 6px;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: #00000004;
      }
      -webkit-tap-highlight-color: #00000000;
    }

    input[type="radio"] {
      /* Remove most all native input styles */
      appearance: none;
      // Microsoft Edge and IE Mobile support this property with the -webkit- prefix,
      // rather than -ms- for interop reasons (https://caniuse.com/?search=appearance)
      -webkit-appearance: none;
      // -moz-appearance:none did not remove the dropdown arrow in the select tag
      //(https://caniuse.com/?search=appearance)
      -moz-appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      /* Not removed via appearance */
      margin: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
    }

    input[type="radio"]::before {
      content: "";
      width: 100%;
      height: 2px;
      transform: scale(0);
      transition: all 0.15s ease-in-out;
      background-color: $primary-color;
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }

    label {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    .auth-form-container {
      width: 90%;
    }
  }
  .fp-envelope {
    background-image: url("./../../assets/images/forgot-password-envelope.svg");
    min-height: 115px;
    max-width: 144px;
    width: 100%;
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    margin: 0 auto;
  }
}

// .auth-form-container {
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   flex: 50%;
//   padding: 120px 100px;
//   box-sizing: border-box;

//   // Responsive Mobile
//   @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//     padding: 33px 19px;
//     z-index: 1;
//     overflow: visible;
//     background-color: #ffffff;
//     max-width: 335px;
//     width: 100%;
//     margin: 0 auto;
//     display: block;
//     box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000020;
//     border-radius: 3px;
//     margin-bottom: 30px;
//     margin-top: -38%;
//   }

//   // Responsive Tablet
//   @media screen and (min-width: $break-tablet) and (max-width: $break-large) {
//     padding: 33px 19px;
//     z-index: 1;
//     overflow: visible;
//     background-color: #ffffff;
//     max-width: 650px;
//     width: 100%;
//     margin: 0 auto;
//     display: block;
//     top: -30%;
//     box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000020;
//     border-radius: 3px;
//     margin-bottom: 30px;
//     margin-top: -30%;
//   }

.auth-title {
  @include form-font-large;
  text-align: center;
  font-family: $primary-font-bold;

  // Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
    font-size: 18px;
  }

  // Responsive Tablet
  @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
    font-size: 24px;
  }
}

.auth-description {
  @include form-font-medium;
  // margin-top: 8px;
  opacity: 0.6;
  display: block;
  text-align: center;
}

// .auth-form {
//   display: flex;
//   flex-direction: column;
//   margin-top: 50px;

//   // Responsive Mobile
//   @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//     margin-top: 0;
//   }

//   // Responsive Mobile
//   @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//     min-height: 98px;
//     max-width: 123px;
//     margin: 0 auto;
//     display: block;
//     margin-top: 85px;
//     margin-bottom: 57px;
//   }
// }

.rp-shield {
  background-image: url("./../../assets/images/reset-password-check.svg");
  width: 100%;
  min-height: 159px;
  background-repeat: no-repeat;
  display: block;
  max-width: 130px;
  width: 100%;
  background-size: contain;
  background-position: center;
  margin: 60px auto 63px;

  // Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    min-height: 136px;
    max-width: 100%;
  }
}

.sp-person {
  background-image: url("./../../assets/images/signup-person.svg");
  width: 100%;
  min-height: 119px;
  background-repeat: no-repeat;
  display: block;
  max-width: 137px;
  width: 100%;
  background-size: contain;
  margin: 108px auto 66px;

  // Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    min-height: 136px;
    max-width: 111px;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 53px;
  }
}

.failure-x {
  background-image: url("./../../assets/images/failure.svg");
  min-height: 159px;
  width: 100%;
  max-width: 130px;
  background-repeat: no-repeat;
  display: block;
  background-size: contain;
  background-position: center;
  margin: 10% auto 0;

  // Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    max-width: 100%;
  }
}

//     .auth-input-container {
//       display: flex;
//       flex-direction: column;
//       justify-content: flex-start;
//       margin-top: 27px;

//       // Responsive Mobile
//       @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//         margin-top: 0px;

//         &.custom {
//           margin-top: 10px;
//         }
//       }

//       &:first-child {
//         margin-top: 0;
//       }

//       .auth-dropdown {
//         &:focus {
//           border: 1px solid $color-black;
//           outline: none;
//         }
//       }

//       .auth-label {
//         @include form-font-semibold;
//         // Responsive Mobile & Tablet
//         @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
//           margin-top: 20px;
//         }
//       }

//       .auth-input {
//         overflow: hidden;
//         display: block;
//         width: 100%;
//         border-radius: 3px;
//         border: 1px solid #a9a9a9;
//         box-sizing: border-box;
//         padding: 13px 15px;
//         margin-top: 10px;

//         &::placeholder {
//           @include form-font-regular;
//           opacity: 0.2;
//         }

//         &:focus {
//           border: 1px solid $color-black;
//           outline: none;
//         }
//       }

//       .terms-text {
//         font-family: "Montserrat-Regular", sans-serif;
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 17px;
//         text-align: left;

//         a {
//           color: #a41e24;
//         }
//       }

//       .error-text {
//         span {
//           margin-top: 10px;
//           color: #a41e24;
//           font-family: "Montserrat-SemiBold", sans-serif;
//           font-size: 14px;
//           font-weight: 400;
//           line-height: 17px;
//           text-align: left;
//         }
//       }

//       select {
//         border: 1px solid #f3f3f3;
//         border-radius: 3px;
//         width: 100%;
//         box-sizing: border-box;
//         padding: 13px 15px;
//       }
//     }

//     .auth-subinput-container {
//       margin-top: 20px;
//       display: flex;
//       justify-content: space-between;

//       .auth-checkbox-container {
//         display: flex;
//         align-items: center;

//         input {
//           width: 18px;
//           height: 18px;
//         }

//         .auth-sublabel {
//           @include form-font-medium;
//           margin-left: 8px;
//         }
//       }

//       .auth-link {
//         @include form-font-medium;
//         text-decoration: none;

//         // Responsive Mobile & Tablet
//         @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
//           text-align: center;
//           width: 100%;
//         }
//       }
//     }

//     .auth-submit-button {
//       @include button-red;
//       width: 100%;
//       margin-top: 16px;
//     }

//     .auth-signup-submit-button {
//       @include button-red;
//       width: 100%;
//       margin-top: 16px;
//       transition: 0.3s;
//     }

//     .auth-signup-submit-disabled {
//       @include form-font-medium;
//       background-color: $gray-button-color;
//       border-radius: 3px;
//       border: none;
//       width: 100%;
//       margin-top: 16px;
//       text-align: center;
//       color: #000;
//       padding: 13px 26px;
//       transition: 0.3s;
//     }

//     .auth-text-container {
//       @include form-font-medium;
//       margin-top: 40px;

//       // Responsive Mobile
//       @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//         margin-bottom: 20px;
//         text-align: center;
//         margin-top: 20px;
//       }

//       // Responsive Tablet
//       @media screen and (min-width: $break-tablet) and (max-width: $break-large) {
//         text-align: left;
//       }

//       .auth-link {
//         text-decoration: none;
//         @include form-font-medium;
//         color: $submit-button-background-color;
//         margin-left: 3px;
//       }

//       // Responsive Mobile
//       @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
//         text-align: left;
//       }
//     }
//   }
// }
