@import "../../../../index.scss";

.how-it-works-wrapper {
  background-color: #f8f8f8;

  @include homepage-padding();

  .how-it-works-heading {
    margin-bottom: 150px;
  }

  .how-it-works-cards-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    padding: 0;

    z-index: 1;

    .line {
      @include homepage-line-centered-top;
    }

    .card-wrapper {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      .card-heading {
        font-size: 24px;
      }
    }

    @media (max-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;

      .card-wrapper {
        gap: 15px;
      }
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      text-align: center;
      .card-heading {
        font-size: 24px;
      }
      .card-wrapper {
        align-items: center;
      }
    }
  }
}
