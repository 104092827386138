.page-loading {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  .page-loading-container {
    width: 40%;
    height: 40%;

    .page-loading-icon {
      display: flex;
      justify-content: center;
    }

    .page-loading-text {
      padding: 20px 0;
      text-align: center;
    }
  }
}
