@import "./src/index.scss";

.footer-wrapper {
  background-color: #f8f8f8;
  @include homepage-padding();

  .footer-heading {
    margin-bottom: 150px;
  }

  .footer-cards-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(calc(33.33% - 10px), 1fr));

    padding: 0;

    z-index: 1;

    .line {
      @include homepage-line-centered-top;
    }

    .card-wrapper {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .card-icon {
        margin-bottom: 22px;
      }
      .card-paragraph {
        margin-bottom: 5px;
        color: #000;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .footer-cards-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
.footer-bg {
  height: 157px;

  .primary-color-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 357px;
    .footer-paragraph {
      color: #fff;
      margin-top: 66px;
      .footer-link {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
