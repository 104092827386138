@import "./src/index.scss";

//Grid
.auth-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  //Responsive Mobile & Tablet
  @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
    // display: block;
    // background-color: #f8f8f8;
    // padding-bottom: 30px;
    // min-height: initial;
  }
}
