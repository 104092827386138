@import "./../../../index.scss";

.profile-summary-container {
  background-color: #ffffff;
  text-align: center;
  height: 90vh;
  overflow: hidden;
  margin-top: 5px;

  .profile-summary-inner-container {
    margin-top: 15%;
    .profile-summary-image {
      width: 137px;
      height: 119px;
      background-image: url("./../../../assets/images/signup-person.svg");
      margin: 0 auto;
      margin-bottom: 55px;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        max-width: 117px;
        width: 100%;
      }
    }

    .profile-summary-title {
      color: #000000;
      font-family: $primary-font-semibold;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;
    }

    .profile-summary-button {
      @include form-font-medium;
      background-color: $gray-button-color;
      border-radius: 3px;
      border: none;
      text-align: center;
      color: $color-black;
      padding: 13px 24px;
      cursor: pointer;
      outline: none;
    }

    .profile-summary-info {
      font-family: $primary-font;
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 14px;
    }
  }
}
