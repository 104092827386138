@import "../../index.scss";

.auth-background {
  position: relative;
  margin-top: 115px;
  height: 392px;
  background: $primary-color;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(20, 33, 43, 0.1);

  padding: 70px;

  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  .illustration {
    position: absolute;
    left: 0;
    top: 0;
  }

  .auth-typography {
    color: #fff;
    z-index: 1;

    .auth-heading {
      font-size: 64px;
      line-height: 78px;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
}
