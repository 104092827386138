@import "./src/index.scss";

.validationError {
  @include validation-error-text;

  display: flex;
  flex-direction: column;
  margin: 15px 0;
  overflow: hidden;
  font-size: 15px;
  line-height: 18px;

  // Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    font-size: 14px;
  }
}
