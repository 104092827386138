@import "./src/index.scss";

.sidebar-admin-comp-fields {
  width: 90%;
  margin: 0 auto;
  margin-top: 10%;

  .admin-comp-field {
    margin: 20px 0;
    border-bottom: 1px solid #a1a1a115;
    width: 100%;

    .admin-comp-field-title {
      @include form-font-semibold;
      margin-bottom: 8px;
      font-size: 14px;
    }

    .admin-comp-field-value {
      @include form-font-medium;
      padding: 10px 0;
      color: #808080;

      &.custom {
        display: flex;
        align-items: center;
        background-color: #f8f8f8;
        border: 1px solid #ecebeb;
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 10px;

        p {
          @include form-font-medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 10px;
          margin-left: 10px;
        }

        .document-icons {
          fill: #a41e24;
        }

        .link {
          margin-left: auto;
        }
      }
    }
  }
}
