@import "./src/index.scss";
.admin-search-wrapper {
  display: flex;

  .admin-search-field {
    @include form-font-medium;
    min-width: 400px;
    padding: 5px 10px;
    border: 1px solid #a1a1a1;
    border-right: none;
    border-radius: 3px 0 0 3px;

    &:focus {
      outline: none;
      border: 1px solid $color-black;
    }
  }

  .admin-search-button {
    @include form-font-medium;
    min-width: 100px;
    padding: 10px 15px;
    color: #fff;
    background: #a41e24;
    border: 1px solid #a41e24;
    border-radius: 0 3px 3px 0;
    border-left: 0;
    font-weight: 700;
    text-align: center;
    outline: none;
    &:hover {
      opacity: 0.85;
    }
  }
}

.admin-table-container {
  margin: 30px auto;
  padding: 30px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000020;

  .admin-table {
    width: 100%;
    margin: 30px 0;

    * {
      border-bottom: 1px solid #a1a1a115;
    }

    .admin-head {
      font-size: 1.15em;

      th {
        @include form-font-semibold;
        font-size: 16px;
        line-height: 19px;
      }
    }

    .admin-row {
      width: 100%;
      display: grid;

      * {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 15px 10px;
      }

      .admin-table-delete {
        padding: 0 10px;
        border: 0;
        cursor: pointer;
        border-radius: 10px;

        &:hover {
          background: #a1a1a122;
        }
      }
    }
  }
}
