@import "./src/index.scss";

.navbar-container {
  width: 100%;
  background-color: $color-white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .navbar-wrapper {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1700px;
    width: 100%;
    height: 80px;

    // Responsive Mobile
    @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
      justify-content: space-around;
    }

    // Responsive Tablet
    @media screen and (min-width: $break-tablet) and (max-width: $break-large) {
      justify-content: space-between;
    }

    .navbar-logo {
      text-decoration: none;
      color: $submit-button-background-color;
      @include navigation-bar-logo;

      span {
        color: $color-black;
        margin-left: 10px;
      }
    }

    .navbar-links {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      margin: 0 15px;

      .navbar-link {
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        @include form-font-medium;
        padding: 0 10px;
        margin: 0 10px;
        border-bottom: 3px solid transparent;

        &.active {
          border-bottom: 3px solid #a41e24;
        }
      }
    }

    .navbar-user {
      position: relative;
      @include form-font-medium;
      width: auto;
      display: flex;
      justify-content: flex-end;

      // Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        max-width: 140px;
        width: 100%;
        height: 33px;
        overflow: hidden;
        margin-left: 10px;
        margin-top: 10px;
      }

      .navbar-arrow {
        margin-left: 10px;
        font-weight: bold;

        path {
          fill: $submit-button-background-color;
        }

        &:hover {
          cursor: pointer;
        }
        // Responsive Mobile
        @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
          margin-right: 10px;
        }
      }

      .nav-dropdown-menu {
        position: absolute;
        top: 49px;
        right: 0;
        font-size: 14px;
        color: #000;
        font-weight: 400;
        line-height: 17px;
        text-align: left;
        cursor: pointer;
        background-color: #fff;
        z-index: 100;
        min-width: 242px;
        height: auto;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);

        // Responsive Mobile
        @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
          min-width: 100vw;
          display: flex;
          flex-direction: column;
          position: fixed;
          top: 12%;
        }

        .nav-dropdown-item {
          width: auto;
          padding: 15px;
          display: flex;
          align-items: center;

          // Responsive Mobile
          @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
            width: 100%;
          }
        }

        .nav-dropdown-item:hover {
          background-color: #fbfbfb;
        }

        .nav-dropdown-item-icon {
          color: #b14248;
          margin-right: 15px;
          font-size: 13px;
        }
      }
    }
  }
}
