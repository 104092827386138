.custom-select-field-wrapper {
  .custom-select-label {
    font-weight: 700;
    font-size: 0.9em;
  }

  .custom-select-field {
    margin: 10px 0 0;
    outline: none;

    &:focus {
      border: 1px solid black;
      outline: none;
    }

    input[type="text"] {
      margin-top: -10px;
    }
  }
}
