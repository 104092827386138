@import "../../../../index.scss";

.who-can-join-wrapper {
  background-color: $homepage-background-color;

  .box-background {
    position: relative;
    top: -150px;
    height: 370px;
    background: $primary-color;

    .who-can-join-heading {
      color: #fff;
      z-index: 1;
    }

    .who-can-join-illustration {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: rotate(-180deg);
    }
  }

  .papper-wrapper {
    position: relative;
    @include homepage-padding;

    display: flex;
    justify-content: center;
    gap: 80px;

    top: -300px;

    .who-can-join-subheading {
      margin-bottom: 50px;
    }

    .who-can-join-paragraph {
      margin-bottom: 40px;
    }

    .who-can-join-paragraph-bottom {
      margin-bottom: 50px;
    }

    .list-items-wrapper {
      .list-item {
        padding-left: 0;
        padding-right: 0;
        .MuiListItemIcon-root {
          min-width: 0;
          margin-right: 17px;
        }
        .MuiTypography-body2 {
          color: #000;
        }
      }
      margin-bottom: 40px;
    }

    .who-can-join-link {
      font-family: $primary-font-semibold;
      color: $primary-color;
      font-size: 15px;
      line-height: 22px;

      cursor: pointer;

      text-decoration: none;

      .who-can-join-icon {
        background-color: none;
        background: none;
        margin-left: 5px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        .who-can-join-icon {
          background-color: $homepage-background-color;
          transform: rotate(-35deg);
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .papper-wrapper {
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 500px) {
    .papper-wrapper {
      gap: 40px;

      .who-can-join-subheading {
        margin-bottom: 30px;
      }

      .who-can-join-paragraph {
        margin-bottom: 20px;
      }
      .who-can-join-paragraph-bottom {
        margin-bottom: 30px;
      }

      .who-can-join-link {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
}
