@import "./src/index.scss";
@import "../../homePage.scss";

.hero-section {
  position: relative;
  background-color: $primary-color;

  display: flex;
  justify-content: space-between;

  gap: 2rem;
  overflow: hidden;
  width: 100%;
  margin: 115px auto 0;

  .bg-illustration-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }

  .content-wrapper {
    padding-left: $homepage-padding;
    z-index: 3;
    margin-top: 12%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    z-index: 2;

    .buttons-wrapper {
      display: flex;
      gap: 20px;

      .button-outlined-white {
        border-color: #fff;
        background-color: $primary-color;
        color: #fff;

        &:hover {
          background-color: #fff;
          color: $primary-color;
        }
      }

      .button-contained-white {
        background: #fff;
        color: $primary-color;
      }
    }
  }

  .image-wrapper {
    z-index: 1;
    .hero-section-image {
      float: right;
      margin-left: auto;
      height: auto;
    }
  }

  @media screen and (max-width: 1280px) {
    gap: 0.2rem;

    .content-wrapper {
      padding: 0 $homepage-padding-lg;
      gap: 30px;
      width: 28%;
    }
  }

  @media screen and (max-width: 900px) {
    gap: 0;

    .content-wrapper {
      padding: 0 $homepage-padding-lg;
      margin-top: 18%;
    }
  }

  @media screen and (max-width: 768px) {
    .content-wrapper {
      padding: $homepage-padding-lg;
      gap: 20px;
      margin-top: 5%;
      width: 100%;
    }
    .image-wrapper {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    margin: 100px auto 0;
    padding: $homepage-padding-sm;
    width: fit-content;
    .content-wrapper {
      padding: $homepage-padding-sm;
    }

    @media screen and (max-width: 500px) {
      .content-wrapper {
        padding: 0;
        padding: $homepage-padding-sm 0;
        gap: 30px;
        margin-top: 5%;
        // text-align: center;
      }
      .buttons-wrapper {
        flex-direction: column;
      }
    }
  }
}
