@import "../../../../index.scss";

.what-we-offer-wrapper {
  background-color: #f8f8f8;

  @include homepage-padding();

  .what-we-offer-heading {
    margin-bottom: 150px;
  }

  .what-we-offer-content-wrapper {
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 78px;
    position: relative;

    .line {
      @include homepage-line-centered-top;
    }

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .box-wrap {
    width: 50%;
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    .what-we-offer-image {
      width: 100%;
      height: auto;
    }
    .what-we-offer-subheading {
      margin: 48px 0px 10px;
    }
  }

  @media only screen and (max-width: 500px) {
    .what-we-offer-content-wrapper {
      gap: 20px;
    }
    .box-wrap {
      .what-we-offer-subheading {
        margin: 28px 0px 5px;
      }
    }
  }
}

.primary-color-bg {
  position: relative;
  top: -150px;
  height: 320px;
  background: $primary-color;
  overflow: hidden;

  .illustration {
    position: absolute;
    left: 0;
    top: 0;
  }
}
