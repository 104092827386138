@import "../tableStyles.scss";
@import "../../../index.scss";

.admin-table-container {
  .title-btn-wrapper {
    display: flex;
    justify-content: space-between;

    h2 {
      @include form-font-semibold;
      font-size: 18px;
      line-height: 22px;
    }

    .export-button {
      @include form-font-medium;
      min-width: 100px;
      padding: 10px 15px;
      color: #fff;
      background: #a41e24;
      border: none;
      border-radius: 3px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      outline: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  .admin-table {
    .admin-row-experts {
      grid-template-columns: 2.5fr 4fr 2fr 4fr 1.5fr 1.5fr 1.5fr 1fr;
      td {
        @include form-font-medium;
      }
    }
    tr.align-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .single-row {
      &:hover {
        background-color: #f6f6f6;

        .clickable-cell {
          cursor: pointer;
        }
      }

      .custom {
        span {
          border-bottom: none;
          margin: 0 auto;
          @include form-font-medium;
        }
      }
    }

    .custom-center {
      justify-self: center;
    }
    .admin-display-inline {
      display: table-row-group;
    }
  }
}
