@import "../tableStyles.scss";
@import "../../../index.scss";

.admin-table-container {
  .no-list-text-wrapper {
    text-align: center;
  }

  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-btn-wrapper {
    display: flex;
    justify-content: space-between;

    h2 {
      @include form-font-semibold;
      font-size: 18px;
      line-height: 22px;
    }

    .export-button {
      @include form-font-medium;
      min-width: 100px;
      padding: 10px 15px;
      color: #fff;
      background: #a41e24;
      border: none;
      border-radius: 3px;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      outline: none;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  .admin-table {
    .admin-row-companies {
      grid-template-columns: 2fr 3fr 3.5fr 2fr 2fr 1.5fr 2fr 1fr 1fr;
      td {
        @include form-font-medium;
      }
    }

    .single-row {
      &:hover {
        background-color: #f6f6f6;

        .clickable-cell {
          cursor: pointer;
        }
      }

      .custom {
        span {
          border-bottom: none;
          margin: 0 auto;
          @include form-font-medium;
        }
      }
    }

    tr.align-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .custom-center {
      justify-self: center;
    }

    .center-checkbox {
      justify-content: center;
    }

    .admin-display-inline {
      display: table-row-group;
    }

    .checkbox-wrapper {
      position: relative;
      cursor: pointer;
      border: none;
      padding: 11px !important;
    }

    /* Hiding the default checkbox */
    .checkbox-wrapper input {
      display: none;
    }

    /* Creating a custom checkbox */
    .checkmark {
      border-radius: 3px;
      border: 1px solid $color-dark-red;
      position: absolute;
      left: 0;
      padding: 11px !important;

      &:hover {
        background: #a1a1a122;
      }
    }

    /* When the checkbox is checked */
    .checkbox-wrapper input:checked ~ .checkmark {
      background-color: $color-dark-red;
    }

    /* Creating the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Showing the checkmark when checked */
    .checkbox-wrapper input:checked ~ .checkmark:after {
      display: block;
    }

    /* Styling the checkmark/indicator */
    .checkbox-wrapper .checkmark:after {
      left: 5px;
      bottom: 5px;
      width: 9px;
      height: 15px;
      border: solid whitesmoke;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
