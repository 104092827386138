@import "./src/index.scss";

.homepage-header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    padding: 0px 150px;

    max-width: 2500px;
    width: 100%;

    margin: 0 auto;

    .buttons-wrapper {
      display: flex;
      gap: 25px;
      padding: 25px 0px;

      @media (max-width: 768px) {
        gap: 15px;
      }

      a {
        font-family: $primary-font-semibold;
      }
    }

    .header-logo {
      box-shadow: none;
      width: 100%;
      padding: 25px 0px;
    }

    .hamburger-menu-wrapper {
      .hamburger-menu {
        color: $primary-color;
      }
    }
    .drawer-header {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      padding: 5rem;
      .drawer-close-button {
        color: $primary-color;
      }
    }

    .drawer-body {
      .menu-item {
        color: $color-black;
      }
    }
  }

  @media (max-width: 1280px) {
    .header-wrapper {
      padding: 0 $homepage-padding-lg;
    }
  }
  @media (max-width: 900px) {
    .header-wrapper {
      padding: 0 $homepage-padding-sm;
    }
  }
  @media (max-width: 768px) {
    .buttons-wrapper {
      gap: 15px;
    }

    .header-wrapper {
      padding: 0 $homepage-padding-sm;
    }
  }

  @media (min-width: 600px) {
    .hamburger-menu-wrapper {
      display: none;
    }
  }
  @media (max-width: 600px) {
    // padding: 50px 0px;

    .header-logo {
      box-shadow: none;
      max-width: 150px;
    }

    .header-wrapper {
      .buttons-wrapper {
        display: none;
      }
    }
  }
}

.MuiDrawer-paper {
  display: flex;
  align-items: flex-end;
  .MuiIconButton-root {
    margin: 25px 25px 0 0;
  }
  .drawer-body {
    .menu-item {
      margin: 10px 20px;
      font-family: $primary-font-semibold;
      background: $primary-color;
      padding: 15px 50px;
      border-radius: 6px;
      color: #fff;
      font-size: 12px;
      line-height: 22px;
      box-shadow: 0px 0px 20px rgba(20, 33, 43, 0.1);
    }
  }
}
.MuiBackdrop-root {
  background-color: rgba($color: #000000, $alpha: 0.2) !important;
}
