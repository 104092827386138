@import "./src/index.scss";

.sidebar {
  position: fixed;
  z-index: 4;
  overflow: auto;
  top: 0px;
  right: -500px;
  width: 500px;
  opacity: 0;
  padding: 0;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000088;
  color: #000;
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);

  &.active {
    right: 0px;
    opacity: 1;
  }

  .items {
    .item {
      h1 {
        @include form-font-semibold;
        padding: 0 15px;
        margin: 50px 0px 10px;
        font-size: 21px;
        line-height: 25px;
      }

      h3 {
        @include form-font-medium;
        padding: 0 15px;
        margin-bottom: 50px;
        opacity: 0.2;
      }
    }
  }
  .x-icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10%;
    margin-right: 5px;
    padding: 5px;
    cursor: pointer;
    fill: #a41e24;
  }

  .tabs {
    display: flex;
    flex-direction: row;

    .tab {
      font-weight: 700;
      background-color: #a1a1a122;
      border: 1px solid #a1a1a122;
      min-height: 60px;
      font-family: "Montserrat-Regular", sans-serif;
      width: 100%;
      outline: none;

      &.active-tab {
        background-color: #a41e24;
        color: #fff;
      }

      &:hover {
        opacity: 0.85;
        cursor: pointer;
      }
    }
  }

  #sidebar-download-btn {
    padding: 0;
    width: 90%;
    margin: auto;
  }
}
