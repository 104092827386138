@import "./src/index.scss";

.admin-comp-details-tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 20px 0 -1px;

  .admin-comp-tab {
    font-weight: 700;
    background-color: #a1a1a122;
    border: 1px solid transparent;
    min-height: 60px;
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 13px;

    &.active-tab {
      background-color: #a41e24;
      color: #fff;
    }

    &:hover {
      opacity: 0.85;
      cursor: pointer;
    }

    &:first-child {
      border-radius: 5px 0px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 5px 0px 0px;
    }
  }
}

.admin-comp-details-tab-content {
  min-height: 200px;
  padding: 20px;
  border: 2px solid #a1a1a122;

  .company-info {
    margin-top: 10px;

    h3 {
      @include form-font-medium;
      opacity: 0.6;
      padding-top: 10px;
    }
  }
}

.admin-comp-fields {
  display: grid;
  grid-template-columns: repeat(3, 31%);
  grid-gap: 3%;
  margin: 0 auto;

  &.custom {
    display: block;
  }

  .admin-comp-field {
    margin: 20px 0;

    .admin-comp-field-title {
      @include form-font-semibold;
      font-size: 14px;
    }

    .admin-comp-field-value-custom-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 49%);
      grid-gap: 2%;
      padding-top: 15px;
    }

    .admin-comp-field-value {
      @include form-font-medium;
      padding: 10px 0;
      color: #808080;

      &.custom {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ecebeb;
        border-radius: 3px;
        padding: 10px;

        p {
          @include form-font-medium;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 10px;
          margin-left: 10px;
        }

        .document-icons {
          fill: #a41e24;
        }

        .link {
          margin-left: auto;
        }
      }
    }
  }
}

.container-loading {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
