@import "./src/index.scss";

.doc-btn-light {
  font-family: Montserrat-Medium, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  background-color: #ebebeb;
  color: #000;
  border-radius: 3px;
  border: none;
  text-align: center;
  padding: 10px 18px;
  cursor: pointer;
}

.doc-btn-red {
  font-family: Montserrat-Medium, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  background-color: #a41e24;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  padding: 10px 18px;
  cursor: pointer;
}

.document-info-text {
  line-height: 40px;
  //Responsive Mobile
  @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
    line-height: 20px;
  }
}

.document-info-subtext {
  font-family: Montserrat-Regular, sans-serif;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 20px;

  @media screen and (max-width: $break-big-mobile) {
    margin-top: 20px;
  }
}

.document-name {
  @include form-font-medium;
  margin: 0 10px;

  @media screen and (max-width: $break-large) {
    margin: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.document-upload-wrapper {
  .close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: #7c7c7c;
    margin-left: auto;
  }

  .docs-container {
    margin-top: 30px;

    .document-info {
      display: flex;
      align-items: center;
      background-color: #f8f8f8;
      border: 1px solid #ecebeb;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
      justify-content: space-between;

      .document-info-content {
        display: flex;
        align-items: center;
      }

      &.view-doc {
        @media screen and (max-width: $break-tablet) {
          position: relative;
        }

        @media screen and (max-width: $break-big-mobile) {
          flex-direction: column;
        }
      }

      .title-icon-wrapper {
        display: flex;
        align-items: center;

        @media screen and (max-width: $break-large) {
          width: 75%;
        }

        @media screen and (max-width: $break-tablet) {
          width: 70%;
        }

        @media screen and (max-width: $break-big-mobile) {
          width: 100%;
        }
      }

      .actions-wrapper {
        @media screen and (max-width: $break-tablet) and (min-width: $break-big-mobile) {
          position: absolute;
          right: 10px;
        }

        @media screen and (max-width: $break-big-mobile) {
          margin-top: 20px;
        }

        span,
        a {
          @include form-font-medium;
          cursor: pointer;
        }

        a {
          margin-right: 25px;
          color: #000;
          text-decoration: none;

          @media screen and (max-width: $break-large) {
            margin-right: 15px;
          }
        }

        span {
          color: $color-dark-red;
          margin-right: 5px;
        }
      }
    }
  }

  .attach-file-wrapper-outer {
    display: inline-flex;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #f8f8f8;

    .attach-file-wrapper-inner {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: $break-large) {
      display: block;
      margin-right: 0;
    }
  }

  .document-controls {
    margin-bottom: 10px;
    margin-top: 30px;

    .document-upload-input {
      display: none;
    }

    .document-upload-button {
      @extend .doc-btn-red;
      margin-right: 15px;
      outline: none;

      //Responsive Mobile
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .document-buttons {
    padding-top: 25px;
    outline: none;
    .document-delete {
      @extend .doc-btn-light;
      outline: none;
      margin-right: 15px;
    }

    .document-download {
      @extend .doc-btn-red;
      outline: none;

      // custom class only for the Preview button in the sidebar
      &.custom {
        margin-top: 30px;
        display: inline-block;
      }
    }

    //Responsive Mobile
    @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
      display: flex;
      font-size: 14px;

      .document-delete,
      .document-download {
        outline: none;
        width: 100%;
      }

      a.document-download {
        line-height: 30px;
      }
    }
  }
}

//Document upload success file
.document-upload-input {
  display: none;
}

input[type="file"]::file-selector-button {
  @include form-font-medium;
  background-color: #a41e24;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  padding: 10px 18px;
  cursor: pointer;
}

.multi-docs-label {
  @include form-font-medium;
  background-color: #a41e24;
  border-radius: 3px;
  border: none;
  text-align: center;
  color: #fff;
  padding: 10px 18px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
}

.document-upload-error {
  color: #a41e24;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  margin-top: 20px;
}
