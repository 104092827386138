@import "../../index";

.profile {
  background-color: $profile-background-color;

  .profile-body {
    max-width: $profile-body-width;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 100px;

    // Mobile & Tablet Responsive
    @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
      background-color: #f8f8f8;
      max-width: 100%;
      padding-bottom: 30px;
    }

    .control-button-container {
      margin: 33px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      //Mobile responsive
      @media screen and (min-width: $break-mobile) and (max-width: $break-tablet) {
        margin: 5% 0px;
      }

      button {
        @include form-font-medium;
        background-color: $submit-button-background-color;
        border-radius: 3px;
        border: none;
        text-align: center;
        color: $color-white;
        padding: 13px 24px;
        margin-left: 20px;
        cursor: pointer;

        &.cancel {
          background-color: $gray-button-color;
          color: $color-black;
        }
      }

      // Mobile & Tablet Responsive
      @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
        min-width: 100vw;
        display: flex;

        button {
          width: 100%;
          margin: 0 10px;
        }
      }

      .control-button-real-container {
        button {
          @include form-font-medium;
          background-color: $submit-button-background-color;
          border-radius: 3px;
          border: none;
          text-align: center;
          color: $color-white;
          padding: 13px 24px;
          margin-left: 20px;
          cursor: pointer;
          outline: none;

          &.cancel {
            background-color: $gray-button-color;
            color: $color-black;
          }
        }

        // Mobile & Tablet Responsive
        @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
          min-width: 100vw;
          display: flex;
          button {
            width: 100%;
            margin: 0 10px;
          }
        }
      }
    }

    .nav-button-container {
      display: flex;
      background-color: $color-white;

      // Responsive Mobile & Tablet
      @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
        flex-direction: column;
        max-width: 95%;
        margin: 0 auto;
      }

      .profile-button {
        @include form-font-medium;
        border-radius: 3px;
        border: none;
        text-align: center;
        padding: 26px;
        flex: auto;
        margin-right: 1.33px;
        outline: none;

        // Responsive Mobile & Tablet
        @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.selected-tab {
          background-color: $already-selected-tab-color;
          color: $color-white;
        }

        &.active-tab {
          background-color: $submit-button-background-color;
          color: $color-white;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .profile-card-body {
      background-color: $color-white;
      padding: 5px 28px 55px;

      // Mobile & Tablet Responsive
      @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
        display: block;
        background-color: #fff;
        max-width: 85%;
        margin: 0 auto;
        padding: 5px 18px 30px;
        box-shadow: 0 19px 38px #00000020, 0 15px 12px #00000020;
        border-radius: 3px;
      }

      .profile-form-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        height: fit-content;

        // Mobile & Tablet Responsive
        @media screen and (min-width: $break-mobile) and (max-width: $break-large) {
          display: block;
        }
      }
    }
  }

  input[type="text"]:disabled {
    background-color: #f2f2f2;
    border-color: #a9a9a9;
  }

  input[type="number"]:disabled {
    background-color: #f2f2f2;
    border-color: #a9a9a9;
  }

  input {
    font-family: $primary-font;
    font-size: 14px;
  }

  .custom-select-field-wrapper {
    font-family: $primary-font;
    font-size: 14px;
  }
}

.styled-input {
  border: 1px solid #a9a9a9;
  &:focus {
    outline: none;
    border: 1px solid $color-black;
  }
}
